import React, { useState } from "react";
import axios from "axios";
function Index({location}) {
  const [terms, setTerms] = useState(false);
  const [status, setStatus] = useState({
    submitted: false,
    submitting: false,
    info: { error: false, msg: null },
  });
  const [inputs, setInputs] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    company_name: "",
    total_empolyees: "",
    interest: "",
  });
  const handleServerResponse = (ok, msg) => {
    if (ok) {
      setStatus({
        submitted: true,
        submitting: false,
        info: { error: false, msg: msg },
      });
      setInputs({
        first_name: "",
        last_name: "",
        email: "",
        phone_number: "",
        company_name: "",
        total_empolyees: "",
        interest: "",
      });
    } else {
      setStatus({
        info: { error: true, msg: msg },
      });
    }
  };
  const handleOnChange = (e) => {
    e.persist();
    setInputs((prev) => ({
      ...prev,
      [e.target.id]: e.target.value,
    }));
    setStatus({
      submitted: false,
      submitting: false,
      info: { error: false, msg: null },
    });
  };
  const handleOnSubmit = (e) => {
    e.preventDefault();
    setStatus((prevStatus) => ({ ...prevStatus, submitting: true }));
    axios({
      method: "POST",
      url: "https://formspree.io/f/mrgjrpqy",
      data: inputs,
    })
      .then((response) => {
        handleServerResponse(
          true,
          "Thank you, your message has been submitted."
        );
      })
      .catch((error) => {
        handleServerResponse(false, error.response.data.error);
      });
  };
 
  return (
    <div className="w-full bg-black">
      <div className="max-w-[1170px] mx-auto pt-16 md:pt-0">
        <div className="py-12">
          <div className="flex flex-wrap justify-center mb-16 lg:justify-start">
            <div className="w-full px-4 lg:w-2/5 md:w-3/4 lg:h-400 ">
              <div className="flex flex-col items-center justify-center text-white lg:block">
                <p className="mt-10 mb-4 text-xs font-normal tracking-widest">
                  contact us
                </p>
                <h1 className="mb-6 text-4xl font-medium md:text-5xl">
                  Let's connect
                </h1>
                <p className="text-lg font-normal text-center md:text-xl md:mb-8 lg:text-left">
                  Interested in technology solutions for your business?  Call
                  1-844-365-9460 or fill out the form below, and someone will
                  get back to you.  We’d love to answer your questions on:
                </p>
                <div className="mt-16 ml-4">
                  <ul className="mb-6 list-disc md:mb-0">
                    <li className="mb-3">Communications & Call Center</li>
                    <li className="mb-3">Integrations</li>
                    <li className="mb-3">Networking</li>
                    <li className="mb-3">Security</li>
                    <li className="mb-3">Analytics & Reporting</li>
                    <li className="mb-3">AI</li>
                    <li className="mb-3"> Operational Support</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="w-full lg:w-3/5 lg:px-6 md:px-4 ">
              <div className="px-8 pt-10 pb-12 bg-white sm:mx-8 sm:rounded-2xl">
                <div className="px-3">
                  <h1 className="mb-5 text-base font-bold">Get in touch</h1>
                </div>
                <form onSubmit={handleOnSubmit} method="post" className="flex flex-col">
                  <div className="flex flex-wrap">
                    <div className="w-full px-3 md:w-2/4">
                      <input
                        id="first_name"
                        type="text"
                        placeholder="First Name"
                        className="w-full h-12 px-5 mb-8 rounded-lg focus:outline-none border-form placeholder-grey-darkest"
                        value={inputs.first_name}
                        name="_replyto"
                        onChange={handleOnChange}
                      />
                    </div>
                    <div className="w-full px-3 md:w-2/4">
                      <input
                      id="last_name"
                        type="text"
                        placeholder="Last Name"
                        className="w-full h-12 px-5 mb-8 rounded-lg focus:outline-none border-form placeholder-grey-darkest"
                        name="_replyto"
                        onChange={handleOnChange}
                        required
                        value={inputs.last_name}
                      />
                    </div>
                  </div>
                  <div className="flex flex-wrap">
                    <div className="w-full px-3 md:w-2/4">
                      <input
                      id="email"
                        type="text"
                        placeholder="Email"
                        className="w-full h-12 px-5 mb-8 rounded-lg focus:outline-none border-form placeholder-grey-darkest"
                        name="_replyto"
                        onChange={handleOnChange}
                        required
                        value={inputs.email}
                      />
                    </div>
                    <div className="w-full px-3 md:w-2/4">
                      <input
                       id="phone_number"
                        type="text"
                        placeholder="Number"
                        className="w-full h-12 px-5 mb-8 rounded-lg focus:outline-none border-form placeholder-grey-darkest"
                        onChange={handleOnChange}
                        required
                        value={inputs.phone_number}
                      />
                    </div>
                  </div>
                  <div className="flex flex-wrap">
                    <div className="w-full px-3 md:w-2/4">
                      <input
                       id="company_name"
                        type="text"
                        placeholder="Company Name"
                        className="w-full h-12 px-5 mb-8 rounded-lg focus:outline-none border-form placeholder-grey-darkest"
                        onChange={handleOnChange}
                        required
                        value={inputs.company_name}
                      />
                    </div>
                    <div className="w-full px-3 md:w-2/4">
                      <div className="w-full h-12 px-5 mb-8 overflow-hidden rounded-lg border-form placeholder-grey-darkest">
                        <label
                          for="employee"
                          className="text-xs text-grey-darkest"
                        >
                          Employees
                        </label>
                        <input
                         id="total_empolyees"
                          type="text"
                          placeholder="Select"
                          className="focus:outline-none"
                          onChange={handleOnChange}
                          required
                          value={inputs.total_empolyees}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-wrap w-full">
                    <div className="w-full px-3 overflow-hidden md:w-2/4">
                      <div className="w-full h-12 px-5 mb-8 rounded-lg border-form placeholder-grey-darkest">
                        <label
                          for="interest"
                          className="text-xs text-grey-darkest"
                        >
                          What are you interested in?
                        </label>
                        <input
                         id="interest"
                          type="text"
                          placeholder="Select"
                          className="focus:outline-none"
                          onChange={handleOnChange}
                          required
                          value={inputs.interest}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="flex px-3 mb-9">
                    <input
                      type="checkbox"
                      onChange={() => setTerms(!terms)}
                      className="mt-6"
                    />
                    <span className="pt-5 ml-2 text-gray-light">
                      By checking this box, you agree to be contacted via phone
                      and email regarding your interest in our products and
                      services. We will treat your data in accordance with our
                      privacy policy.
                    </span>
                  </div>
                  <div className="px-3">
                    <input
                      type="submit"
                      value={
                        !status.submitting
                          ? !status.submitted
                            ? "Submit"
                            : "Submitted"
                          : "Submitting..."
                      }
                      className="w-full h-12 text-white bg-black rounded-lg cursor-pointer btn-submit"
                    />
                  </div>
                </form>
                {status.info.error && (
                  <div className="pt-3 text-sm text-center text-red-500">
                    Error: {status.info.msg}
                  </div>
                )}
                {!status.info.error && status.info.msg && (
                  <p className="pt-3 text-xl font-medium text-center text-slate-800">
                    {status.info.msg}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Index;
